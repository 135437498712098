import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Main from 'layouts/Main';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import Container from 'components/Container';
import {
  About,
  Advantages,
  Features,
  Integrations,
  Reviews,
  Team,
  VideoSection,
} from './components';

const Service = () => {
  const theme = useTheme();
  return (
    <Main>
      <Box
        sx={{
          backgroundColor: theme.palette.alternate.main,
          backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.main} 100%)`,
          position: 'relative',
          marginTop: -13,
          paddingTop: 13,
        }}
      >
        <Container position={'relative'} zIndex={3}>
          <About />
        </Container>
        <Box bgcolor={'alternate.main'}>
          <Container position={'relative'} zIndex={3}>
            <p>
              <h2 style={{ textAlign: 'center' }}>ความเป็นมาของโครงการ</h2>
              &nbsp;&nbsp;&nbsp;ด้วยความก้าวหน้าทางด้านวิทยาการระบบอัตโนมัติ
              (Automation) การเติบโตของอุตสาหกรรม IoT (Internet of Things)
              ตลอดจนความเปลี่ยนแปลงอันก่อให้เกิดประโยชน์อย่างกว้างขวางของปัญญาประดิษฐ์
              ได้ผลักดันให้กระบวนการผลิตทางด้านอุตสาหกรรมก้าวรุดหน้าไปอย่างรวดเร็ว
              จนหลายประเทศชั้นนำทางด้านอุตสาหกรรมการผลิตของโลก อันได้แก่
              ประเทศเยอรมันนี ญี่ปุ่น ฯลฯ ได้มีการตั้งเป้าหมายไว้ว่า ในปี 2020
              เป็นต้นไป เทคโนโลยีอุตสาหกรรมการผลิตของโลกจะก้าวเข้าสู่ยุค
              Industry 4.0 ซึ่งเป็นยุคแห่งความก้าวหน้าทางด้านระบบอัตโนมัติ
              ที่อาศัยเฉพาะเครื่องจักรและระบบอัตโนมัติเท่านั้นในกระบวนการผลิต
              (Full Automation) โดยไม่ใช้มนุษย์แม้แต่น้อย
              ทั้งนี้เพื่อหลีกเลี่ยงปัญหา Human Error
              ที่ทำให้เกิดความผิดพลาดในการผลิตที่อาศัยความถูกต้องแม่นยำสูง
              ซึ่งเมื่อหลายๆประเทศต่างทยอยกันพัฒนาเทคโนโลยีอุตสาหกรรมการผลิตของตนเองให้ก้าวหน้าไปถึงจุดนั้นได้แล้ว
              จะได้เปรียบคู่แข่งในการแข่งขันในตลาดทั้งในด้านการเพิ่มปริมาณผลผลิต
              ประสิทธิภาพการดำเนินงาน ตลอดจนการลดต้นทุนในการผลิต
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;ดังนั้นประเทศใดที่ยังมีความล้าหลังในเทคโนโลยีด้านการผลิต
              ที่ยังใช้เทคโนโลยีที่เก่าล้าสมัยและด้อยประสิทธิภาพ
              ก็จะสูญเสียความสามารถในการแข่งขันในตลาดโลกจนไม่สามารถแข่งขันได้อีก
              ส่งผลถึงการถดถอยในด้านการค้าการลงทุนและเศรษฐกิจโดยรวมของประเทศ
              ซึ่งปัญหาดังกล่าวกำลังกลายเป็นปัญหาเร่งด่วนที่ทางรัฐบาลไทยปัจจุบันกำลังให้ความสำคัญอย่างยิ่ง
              ต้องรีบแก้ไขก่อนที่ทุกอย่างจะสายเกินไป
              โดยที่รัฐบาลจะเร่งสร้างกลไกและโครงสร้างพื้นฐานที่สำคัญในอันที่จะผลักดันให้อุตสาหกรรมของไทย
              ดิ้นหลุดออกจากอุปสรรคของความล้าหลังทางด้านเทคโนโลยี
              เพื่อให้อุตสาหกรรมไทยสามารถพัฒนาเทคโนโลยีการผลิตที่ก้าวหน้าทันสมัยต่อยอดไปสู่
              Industry 4.0 ขึ้นมาได้เอง เร่งสร้างความรู้
              สร้างทักษะและความสามารถในการวิจัยและพัฒนาเพื่อก้าวสู่อุตสาหกรรมยุคใหม่โดยเร่งด่วน
              จึงเป็นที่มาของการรวมตัวกันระหว่างกลุ่มโรงงานอุตสาหกรรมขนาดย่อม
              และนักวิจัยในสถาบันการศึกษา
              เพื่อสานต่อแนวทางในการพัฒนาอุตสาหกรรมของประเทศดังกล่าว
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;ดังนั้นโครงการนี้จึงนับได้ว่าเป็นความร่วมมือระหว่างมหาวิทยาลัยและภาคอุตสาหกรรมครั้งสำคัญในอันที่จะการพัฒนาเทคโนโลยีการผลิตของประเทศให้ก้าวไปสู่
              Industry 4.0 โดยเฉพาะในกลุ่มผู้ประกอบการขนาดย่อม SME
              ซึ่งมีจำนวนมากและเป็นเสมือนกลไกหลักในการพัฒนาอุตสาหกรรมของประเทศ
              ให้มีเครื่องมือและติดอาวุธพร้อมที่จะไปสู่การแข่งขันกับนานาประเทศ
              ที่กำลังจะมีการแข่งขันกันอย่างรุนแรงในปัจจุบัน
              โดยเป้าหมายที่ตั้งไว้สำหรับโครงการนี้คือ
              การวิจัยและพัฒนาแพลตฟอร์มเปิดของซอฟต์แวร์ SCADA
              สำหรับใช้งานร่วมกันในกลุ่มโรงงาน SME ไทยในราคาประหยัด
              โดยใช้เทคโนโลยีด้านซอฟต์แวร์คอมพิวเตอร์ที่ก้าวหน้ามากที่สุดในปัจจุบัน
              ได้แก่ เทคโนโลยี IoT
              เทคโนโลยีการสื่อสารยุคใหม่ทั้งแบบมีสายและไร้สาย เทคโนโลยีการแสดงผล
              3 มิติในโลกเสมือนจริง เทคโนโลยีปัญญาประดิษฐ์
              และเทคโนโลยีระบบสารสนเทศภูมิศาสตร์
              เทคโนโลยีที่ก้าวล้ำและสำคัญเหล่านี้จะถูกนำมาหลอมรวมกันอย่างสร้างสรรค์
              เพื่อพัฒนาให้เป็นแพลตฟอร์มระบบเปิดของซอฟต์แวร์ SCADA
              สำหรับกลุ่มบริษัท SME ของไทยที่จะมุ่งไปสู่ Industry 4.0
              เพื่อเป็นเครื่องมือและเครื่องจักรอันสำคัญที่จะนำไปใช้ในการแข่งขันและขับเคลื่อนให้เกิดการเติบโตของอุตสาหกรรมในโลกยุคดิจิทัลของประเทศไทยต่อจากนี้ไป
            </p>
          </Container>
        </Box>
        <Container position={'relative'} zIndex={3}>
          <p>
            <h2 style={{ textAlign: 'center' }}>วัตถุประสงค์โครงการ</h2>
            เพื่อพัฒนาซอฟต์แวร์ Thai Open SCADA Platform for SMEs ที่มี Standard
            Features ของ SCADA ที่มีใช้ในตลาดประเทศไทยในราคาประหยัด เพื่อพัฒนา
            Thai Open SCADA Platform for SMEs ที่มีขีดความสามารถที่ SCADA
            ในตลาดทั่วโลกไม่มีคือ
            <p>
              2.1) Unique Agent-based Microservice Architecture ซึ่งประกอบด้วย
              Container และ Sandbox ในรูปแบบ Intelligent Agent ซึ่งใช้ซอฟต์แวร์
              Open Source ในการพัฒนา อาทิเช่น
              <ul>
                <li>Operating system: Linux</li>
                <li>HTTP server: NGINX</li>
                <li>Object-oriented programming language: Python</li>
                <li>Database management system: PostgreSQL, Redis</li>
                <li>GIS: PostGIS</li>
                <li>Container technology: Docker, Kubernetes</li>
                <li>Web development: Django, React, Javascript</li>
                <li>AR and VR: WebXR, WebVR</li>
              </ul>
            </p>
            <p>
              2.2) Artificial Intelligence ในรูปแบบ Intelligent Agent
              ที่พัฒนาด้วยภาษา Python โดยมีความสามารถดังต่อไปนี้:
              การคิดวิเคราะห์ (reasoning) เกี่ยวกับ event, action, optimization,
              possible alternative solutions, problem solving และ programmable
              knowledge
            </p>
            <p>
              2.3) Automatic Workflow ในรูปแบบ Business Process Model and
              Notation (BPMN) Diagram ที่ระบุการทำงานโดยเชื่อมโยง Microservice
              ของแต่ละ Agent เข้าด้วยกันตามลำดับขั้นตอนตามที่ได้กำหนดไว้ใน
              Diagram ซึ่ง Automatic Workflow สามารถโปรแกรมโดยใช้ GUI Widgets
              เพื่อให้กระบวนการผลิตเป็นระบบอัตโนมัติในรูปแบบ SME Digital
              Operating Model ที่เชื่อมและผสมผสาน Top Floor Operation: ERP,
              Order Fulfillment Process และShop Floor Operation: MES
              (Manufacturing Execution System) รวมทั้ง Smart Factory และ Smart
              Warehouse & Logistics เข้าด้วยกัน
            </p>
            <p>
              2.4) Data Visualization ที่เป็นการนำเอาข้อมูล SCADA
              มาแสดงผลในรูปแบบ 3 มิติ ด้วยเทคโนโลยี AR และ VR
              รวมทั้งการแสดงผลข้อมูลในลักษณะเชิงพื้นที่ด้วย GIS
            </p>
            <p>
              3.เพื่อนำ Thai Open Scada Platform for SMEs
              ไปทดสอบใช้งานจริงกับอย่างน้อย 1 ตัวอย่างโรงงานในแต่ละอุตสาหกรรม 2
              กลุ่ม ประกอบด้วย <br />
              &nbsp;&nbsp;&nbsp;(1) อุตสาหกรรมอาหาร เป็นกลุ่มโรงงานผลิตอาหาร และ
              <br />
              &nbsp;&nbsp;&nbsp;(2) โปรแกรมบ้านอัจฉริยะ (Smart Home Application)
              โดยที่
            </p>
            <p>
              3.1) ถ้าเป็นโรงงานขนาดเล็ก จะมีจำนวน tag (data point) ไม่เกิน 500
            </p>
            <p>
              3.2) ถ้าเป็นโรงงานกึ่งขนาดกลาง จะมีจำนวน tag (data point) ตั้งแต่
              500 tag ถึง 1,000 tag เพื่อพัฒนาซอฟต์แวร์ SCADA
              ด้วยเครื่องมือซอฟต์แวร์ Open Source ให้เป็น Open Platform
              ในลักษณะที่กลุ่มพันธมิตร SME
              ไทยเข้ามาร่วมพัฒนาต่อยอดในการตอบสนองกับความต้องการตามลักษณะการใช้ในอุตสากรรม
              SME ไทยที่หลากหลาย Application ได้
            </p>
          </p>
        </Container>

        <Box bgcolor={'alternate.main'}>
          <Container position={'relative'} zIndex={3}>
            <Box sx={{ textAlign: 'center' }}>
              <Card>
                <h2 style={{ textAlign: 'center' }}>องค์ประกอบของแพลตฟอร์ม</h2>

                <p>
                  <Grid container>
                    <Grid item xs={12} sm={7}>
                      <img
                        src={'1.png'}
                        width={'100%'}
                        style={{ maxWidth: '700px' }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={5} style={{ textAlign: 'left' }}>
                      <h4>
                        องค์ประกอบของแพลตฟอร์ม
                        <br />
                        สำหรับ Industry 4.0 มี 8 ส่วนดังนี้
                      </h4>

                      <ul style={{ textAlign: 'left' }}>
                        <li>SCADA (Core)</li>
                        <li>IoT Interface</li>
                        <li> Intelligent Agent</li>
                        <li> Real-time Agent Communication</li>
                        <li>Augmented/Virtual Reality</li>
                        <li> GIS</li>
                        <li>Automatic Workflow</li>
                        <li>Security </li>
                      </ul>
                    </Grid>
                  </Grid>
                </p>
              </Card>
              {/* <Divider /> */}
              <Card sx={{ mt: 2 }}>
                <p>
                  <h3>
                    องค์ประกอบตามฟังก์ชั่นการทำงานของแพลตฟอร์มเปิดของซอฟต์แวร์
                    Thai Open SCADA
                  </h3>
                  <img
                    src="2.png"
                    width={'100%'}
                    style={{ maxWidth: '500px' }}
                  />
                  <br />
                </p>
              </Card>
              {/* <Divider /> */}
            </Box>
          </Container>
        </Box>

        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1921 273"
          sx={{
            position: 'absolute',
            width: '100%',
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 1,
            height: '35%',
          }}
        >
          <polygon
            fill={theme.palette.background.paper}
            points="0,273 1921,273 1921,0 "
          />
        </Box>
      </Box>

      {/* <Container>
        <VideoSection />
      </Container> */}

      {/* <Box bgcolor={'alternate.main'}>
        <Container>
          <Advantages />
        </Container>
      </Box>
      <Container>
        <Features />
      </Container>
      <Divider />
      <Container>
        <Reviews />
      </Container>
      <Container paddingTop={'0 !important'}>
        <Integrations />
      </Container> */}
      <Box bgcolor={'alternate.main'}>
        <Container>
          <Team />
        </Container>
      </Box>
    </Main>
  );
};

export default Service;
